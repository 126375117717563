import React from "react";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

export default function Reviews() {
    const data = useStaticQuery(graphql`
        query ReviewsQuery {
            markdownRemark(frontmatter: {section: {eq: "reviews"}}) {
                frontmatter {
                    reviews {
                        name
                        location
                        date
                        text
                        span
                        image {
                            childImageSharp {
                                gatsbyImageData(width: 90, height: 90, quality: 95, layout: FIXED)
                            }
                            extension
                            publicURL
                        }
                    }
                }
            }
        }
    `)

    return (
        <div className="mx-auto px-4 max-w-screen-xl text-center md:text-left ">
            <span className="mb-16 inline-block text-3xl font-extrabold pb-1 border-b-4 border-green-700">Customer reviews</span>
            <div className="grid md:grid-cols-4 gap-5">
            {data.markdownRemark.frontmatter.reviews.map((edge,j) => {
                return (
                    // md:col-span-1 md:col-span-2 md:col-span-3 md:col-span-4 
                    <div className={`md:col-span-${edge.span} flex flex-col rounded-lg shadow bg-white px-3 pb-4 mb-12`} key={j}>
                        <GatsbyImage
                            image={edge.image.childImageSharp.gatsbyImageData}
                            alt=""
                            className="mx-auto -mt-10 rounded-full shadow mb-6" />
                        <div dangerouslySetInnerHTML={{ __html: edge.text }} className=" mb-4" />
                        <div className="">
                            <span className="font-semibold">{edge.name}</span> 
                            {edge.location &&
                            `, ${edge.location}`
                            }
                        </div>
                        <div className=" text-sm">{edge.date}</div>
                    </div>
                );
            })}
            </div>
        </div>
    );
}
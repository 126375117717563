import React from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";

import Layout from "components/layout";
import SEO from "components/seo";
import Reviews from "components/reviews";
import Contact from "components/contact";

const Index = () => (
    <StaticQuery
        query={graphql`query IndexQuery {
  homeQuery: markdownRemark(frontmatter: {section: {eq: "home"}}) {
    frontmatter {
      title
      metadescription
      title1
      title2
      text
      image {
        childImageSharp {
          gatsbyImageData(width: 350, layout: CONSTRAINED)
        }
        extension
        publicURL
      }
    }
  }
  allMarkdownRemark(
    filter: {frontmatter: {section: {eq: "blog"}}}
    sort: {order: DESC, fields: [frontmatter___date]}
    limit: 3
  ) {
    edges {
      node {
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          excerpt
          title
          featureImage {
            childImageSharp {
              gatsbyImageData(
                width: 400
                height: 280
                placeholder: BLURRED
                layout: CONSTRAINED
              )
            }
          }
        }
      }
    }
  }
}
`}
        render={data => (
            <Layout>
                <SEO
                    pageData={{
                        title: data.homeQuery.frontmatter.title,
                        description: data.homeQuery.frontmatter.metadescription
                    }}
                />
                <div className="">
                    {data.homeQuery.frontmatter.text  &&
                    <div className="bg-green-50 py-10 md:py-24 border-b border-green-100">
                        <div className="page mx-auto px-4 max-w-screen-xl grid md:grid-cols-2 items-end gap-5 lg::gap-40">
                            <div className="lg:pr-20 lg:pt-20 mx-auto">
                                <div className="text-5xl font-black">{data.homeQuery.frontmatter.title1}</div>
                                <div className="text-5xl mb-4 font-black text-green-700">{data.homeQuery.frontmatter.title2}</div>
                                <div className="text-lg">
                                    <div dangerouslySetInnerHTML={{ __html: data.homeQuery.frontmatter.text}} />
                                    <Link to="/about/" className="inline-block rounded py-2 px-8 bg-green-700 text-white font-semibold text-center">Learn more about us</Link>
                                </div>
                            </div>
                            <div>
                                { (!data.homeQuery.frontmatter.image.childImageSharp
                                 && data.homeQuery.frontmatter.image.extension === 'svg'
                                 && <img src={data.homeQuery.frontmatter.image.publicURL} alt="" className="mx-auto" />)
                                 || <GatsbyImage
                                      image={data.homeQuery.frontmatter.image.childImageSharp.gatsbyImageData}
                                      alt=""
                                    />
                                }   </div>
                        </div>
                    </div>
                    }

                    <div className="bg-gray-50 py-12">
                        <div className="mx-auto px-4 max-w-screen-xl mb-12 grid md:grid-cols-2 gap-10 items-center">
                            <div className="col-span-2 md:col-span-1 text-3xl font-extrabold text-center md:text-left ">
                                <span className="inline-block pb-1 border-b-4 border-green-700">Latest blog posts</span>
                            </div>
                            <div className="col-span-2 md:order-last grid md:grid-cols-3 justify-between gap-x-5 gap-y-10">
                            {data.allMarkdownRemark.edges.map((edge,j) => {
                                return (
                                    <Link to={edge.node.fields.slug} className="flex flex-col rounded-lg shadow bg-white pb-4" key={j}>
                                        <GatsbyImage
                                            image={edge.node.frontmatter.featureImage.childImageSharp.gatsbyImageData}
                                            alt=""
                                            className="rounded-t-lg mb-6" />
                                        <div className="px-3 text-sm">{edge.node.frontmatter.date}</div>
                                        <div dangerouslySetInnerHTML={{ __html: edge.node.frontmatter.title }} className="px-3 font-semibold leading-tight text-2xl" />
                                        <div dangerouslySetInnerHTML={{ __html: edge.node.frontmatter.excerpt }} className="px-3 block flex-1 mb-4" />
                                        <div className="px-3 text-right font-semibold text-green-700 uppercase tracking-tight">Read more</div>
                                    </Link>
                                );
                            })}
                            </div>
                            <div className="col-span-2 md:col-span-1 text-center md:text-right font-semibold"><Link to="/blog/" className=" rounded border-2 border-green-700 py-2 px-6 bg-white text-green-700 font-semibold">All blog posts</Link></div>
                        </div>
                    </div>

                    <div className="bg-gray-100 py-12">
                        <Reviews />
                    </div>
                    <Contact />
                                        
                </div>
            </Layout>
        )}
    />
)

export default Index;
